import { FormikProps } from "formik";
import React, { useState } from "react";
import { INPUT_TYPE, StoreInterface } from "../../../assets/interfaces";
import { AppBtn } from "../../ui/buttons";
import ErrorLabel from "../../ui/error-label";
import { ModalBody, ModalFooter } from "../../ui/modal";
import { CheckoutForm } from "../modals/checkout";
import { InputField, SelectDropdown, TextArea } from "@/components/ui/form-elements";
import Checkbox from "@/components/ui/form-elements/checkbox";
import { getFieldvalues } from "@/assets/js/utils/functions";
import next from "next";
import { RequestInterface } from "@/api/utils";

interface Props {
  store: StoreInterface;
  prevStep: VoidFunction;
  form: FormikProps<CheckoutForm>;
  nextStep: VoidFunction;
  createOrderReq: RequestInterface;
}

export type CustomCheckoutFormItem = StoreInterface["configuration"]["custom_checkout_form"][0];

const CustomerFormStep: React.FC<Props> = ({ store, prevStep, form, createOrderReq }) => {
  const [errorText, setErrorText] = useState("");
  const handleNext = () => {
    form.submitForm();
  };

  const extraDetailsErrors = Object.values(form?.errors?.extra_details ?? {});
  return (
    <>
      <form className="flex flex-col flex-auto overflow-hidden">
        <ModalBody className="relative">
          <ErrorLabel error={createOrderReq?.error?.message ?? errorText} setError={setErrorText} />
          <div className="space-y-5">
            {store?.configuration?.custom_checkout_form?.map((component, idx) => (
              <div key={idx}>{getFormComponent(component, form, "extra_details")}</div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex gap-2.5 w-full">
            <AppBtn onClick={prevStep} className="flex-1" color="neutral" size="lg">
              Go Back
            </AppBtn>
            <AppBtn className="flex-1" isBlock onClick={handleNext} disabled={extraDetailsErrors?.length > 0 || createOrderReq.isLoading} size="lg">
              {createOrderReq?.isLoading ? "Placing Order..." : "Place Order"}
            </AppBtn>
          </div>
        </ModalFooter>
      </form>
    </>
  );
};
export default CustomerFormStep;

const getFormComponent = (component: CustomCheckoutFormItem, form: FormikProps<any>, fieldPrefix = "") => {
  const computedName = `${fieldPrefix}.${component.name}`;

  switch (component.type) {
    case INPUT_TYPE.TEXT:
      return <InputField label={component.label} {...getFieldvalues(computedName, form)} />;
    case INPUT_TYPE.NUMBER:
      return <InputField type="number" label={component.label} {...getFieldvalues(computedName, form, "number")} />;
    case INPUT_TYPE.TEXTAREA:
      return <TextArea label={component.label} {...getFieldvalues(computedName, form)} />;
    case INPUT_TYPE.DROPDOWN:
      return (
        <SelectDropdown
          label={component.label}
          {...getFieldvalues(computedName, form)}
          options={component.options.map((o) => ({ text: o, value: o }))}
        />
      );
    case INPUT_TYPE.RADIO:
      return (
        <div className="">
          <h3 className="font-semibold text-sm text-black-muted capitalize"> {component.label} </h3>
          <div className="flex items-center gap-2.5 mt-2.5 flex-wrap">
            {component.options.map((o, i) => (
              <div key={i} className="flex items-center gap-2.5">
                <Checkbox
                  round
                  name={computedName}
                  checked={computedName.split(".").reduce((a, c) => a?.[c], form.values) === o}
                  onChange={() => form.setFieldValue(computedName, o)}
                />
                <label className="text-sm text-black-muted" htmlFor={o}>
                  {o}
                </label>
              </div>
            ))}
          </div>
        </div>
      );
  }
};
