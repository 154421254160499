import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Customer,
  DeliveryArea,
  DELIVERY_METHODS,
  OrderInterface,
  StoreInterface,
  CURRENCIES,
  WhatsappCheckoutOption,
  WABotSession,
} from "../../../assets/interfaces";
import { phoneObjectFromString, phoneObjectToString } from "../../../assets/js/utils/functions";
import CartContext from "../../../contexts/cart-context";
import useSteps from "../../hooks/useSteps";
import Modal from "../../ui/modal";
import CartStep from "../checkout/cart";
import CustomerDetailsStep, { AddressCache } from "../checkout/customer-details";
import FinalizeOrder from "../checkout/finalize-order";
import * as Yup from "yup";
import { phoneValidation } from "../../../assets/js/utils/common-validations";
import { CreateOrderParams } from "../../../api/interfaces/orders-customers.interface";
import { useRequest } from "../../../api/utils";
import { CreateOrder } from "../../../api/orders-customers";
import OrderSuccess from "../checkout/success";
import MakeOrderPayment from "../checkout/make-payment";
import { useLocalObject } from "@/components/hooks/useLocalState";
import CustomerFormStep, { CustomCheckoutFormItem } from "../checkout/custom-form";

export const DEFAULT_ADDRESS_CACHE = { validated: [], userInputed: [] };

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  store: StoreInterface;
  botSession?: WABotSession;
}

const CheckoutModal: React.FC<Props> = ({ show, toggle, store, botSession }) => {
  const customCheckoutForm = store?.configuration?.custom_checkout_form;
  const { step, next, previous, changeStep } = useSteps(
    [
      "cart",
      "customer_details",
      ...(customCheckoutForm?.length > 0 ? ["custom_form"] : []),
      "finalize_order",
      "payment",
      "success",
    ],
    0
  );
  const [scrollToTop, setScrollToTop] = useState(false);
  const [addresses, setAddresses] = useLocalObject<AddressCache>("addresses", DEFAULT_ADDRESS_CACHE);
  const {
    clearCart,
    cart,
    account,
    formatAsCurrency,
    currencies,
    customerDetailsCache,
    setCustomerDetailsCache,
    cartId,
  } = CartContext.useContainer();
  const [order, setOrder] = useState<OrderInterface>();
  const createOrderReq = useRequest(CreateOrder);
  const deliveryAreas = store.delivery_areas as DeliveryArea[];
  const [checkoutOption, setCheckoutOption] = useState<WhatsappCheckoutOption>(null);

  useEffect(() => {
    if (!customerDetailsCache) return;

    let area = "";
    let deliveryContact = {
      phone: {
        code: "+234",
        digits: "",
      },
      name: "",
    };

    const customerDetails = customerDetailsCache ?? null;
    if (!customerDetails) return;

    deliveryContact = customerDetails?.deliveryContact ?? deliveryContact;

    //check for if delivery area has been removed
    if (deliveryAreas.findIndex((a) => a.id === customerDetails.area) > -1) {
      area = customerDetails.area;
    }

    form.setValues({
      ...customerDetails,
      name: customerDetails?.name || "",
      currency: currencies.active,
      address: store?.deliveries_enabled
        ? addresses.validated.length > 0
          ? customerDetails.address
          : ""
        : customerDetails.address,
      deliveryMethod: store?.configuration?.require_delivery_info ? DELIVERY_METHODS.DELIVERY : DELIVERY_METHODS.NONE,
      phone:
        typeof customerDetails.phone === "string"
          ? phoneObjectFromString(customerDetails.phone)
          : customerDetails.phone,
      area,
      coupon: "",
      deliveryContact,
      orderNotes: customerDetails?.orderNotes || "",
      deliveringToSelf: store?.configuration?.require_delivery_info ? customerDetails?.deliveringToSelf ?? true : true,
      extra_details: customCheckoutForm?.length > 0 ? customerDetails?.extra_details : undefined,
    });
  }, [customerDetailsCache, addresses]);

  useEffect(() => {
    const handleWindowClose = (e) => {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = "You have not completed your order, are you sure you want to leave this page?";
      }

      // For Safari
      return "You have not completed your order, are you sure you want to leave this page?";
    };

    if (show && cart.length > 0 && step !== "success") {
      //Notice before closing window
      window.addEventListener("beforeunload", handleWindowClose);
    } else {
      window.removeEventListener("beforeunload", handleWindowClose);
    }

    return () => window.removeEventListener("beforeunload", handleWindowClose);
  }, [show, cart, step]);

  const form = useFormik<CheckoutForm>({
    initialValues: {
      customer_id: "",
      coupon: "",
      name: "",
      email: "",
      phone: {
        code: "+234",
        digits: "",
      },
      deliveryMethod: store?.configuration?.require_delivery_info ? DELIVERY_METHODS.DELIVERY : DELIVERY_METHODS.NONE,
      address: "",
      deliveryContact: {
        phone: {
          code: "+234",
          digits: "",
        },
        name: "",
      },
      area: "",
      orderNotes: "",
      deliveringToSelf: true,
      currency: currencies.active,
      extra_details:
        customCheckoutForm?.length > 0
          ? customCheckoutForm?.reduce((a, b) => {
              a[b.name] = "";
              return a;
            }, {})
          : null,
    },
    onSubmit: async (values) => {
      const key = btoa("CATLOG-USED-COUPONS");
      const storedCoupons = localStorage.getItem(key);
      const coupons: string[] = storedCoupons ? JSON.parse(atob(storedCoupons)) : [];
      setScrollToTop(true);

      //set scrollToTop to false to make sure when it changes back to true it will scroll
      setTimeout(() => {
        setScrollToTop(false);
      }, 500);

      const addressKey = store?.deliveries_enabled ? "validated" : "userInputed";

      // cache address
      if (!addresses[addressKey]?.includes(values.address)) {
        const addressesCopy = { ...addresses, [addressKey]: [...addresses[addressKey], values.address] };
        setAddresses(addressesCopy);
      }

      if (coupons.includes(values.coupon)) {
        setErrorText("You have used this coupon code");
      } else {
        const order = await submitOrder(values);
        setCustomerDetailsCache({
          ...values,
          coupon: "",
          phone: values.phone,
          orderNotes: undefined,
          deliveryContact: {
            ...values?.deliveryContact,
            phone: values.deliveryContact?.phone, //not converting to string cos it's pointless
          },
          customer_id: order?.customer?.id,
        });
      }
    },
    validationSchema: validationSchema(
      deliveryAreas,
      store?.configuration?.require_emails,
      step === "custom_form" ? customCheckoutForm : undefined
    ),
  });

  const submitOrder = async (values: CheckoutForm) => {
    const coupon = values?.coupon ?? undefined;

    const orderParams: CreateOrderParams = {
      store: store.id,
      customer: {
        name: values?.name,
        email: values?.email,
        phone: phoneObjectToString(values?.phone),
        store: store.id,
      },
      delivery_method: values?.deliveryMethod,
      delivery_info:
        values?.deliveryMethod === DELIVERY_METHODS.DELIVERY
          ? {
              delivery_address: values.address,
              name: values?.deliveringToSelf ? values.name : values?.deliveryContact?.name,
              phone: values?.deliveringToSelf
                ? phoneObjectToString(values.phone)
                : phoneObjectToString(values.deliveryContact?.phone),
              area: values?.area,
            }
          : null,
      items: cart,
      coupon: coupon,
      order_notes: values.orderNotes,
      extra_details: values?.extra_details,
      currency: currencies.active,
      cart: cartId.current,
      rates: currencies.rateId,
    };

    const [res, err] = await createOrderReq.makeRequest(orderParams);

    if (res) {
      if (coupon) {
        //save coupon to local storage
        const key = btoa("CATLOG-USED-COUPONS");
        const storedCoupons = localStorage.getItem(key);
        const coupons = storedCoupons ? JSON.parse(atob(storedCoupons)) : [];
        coupons.push(coupon);
        const encodedCoupons = btoa(JSON.stringify(coupons));
        localStorage.setItem(key, encodedCoupons);
      }

      setOrder(res?.data);
      handleNext();

      return res?.data as OrderInterface;
    } else {
      return null;
    }
  };

  const handleNext = () => next();

  const handleExitCheckout = () => {
    const message = steps[step].exitMessage;

    if (message) {
      const confirm = window.confirm(message);

      if (confirm) {
        cleanUp(step === "customer_details" ? false : true);
      }

      return;
    }

    if (step === "success") {
      cleanUp();
      return;
    }

    toggle(false);
  };

  const handlePrev = () => {
    switch (step) {
      case "customer_details":
        changeStep("cart");
        break;
      case "finalize_order":
        handleExitCheckout();
        break;
      case "payment":
        changeStep("finalize_order");
        break;
      default:
        previous();
    }
  };

  const cleanUp = (shouldClearCart: boolean = true) => {
    if (shouldClearCart) {
      clearCart();
    }
    changeStep("cart");
    toggle(false);
  };

  const steps: { [key: string]: { exitMessage: string; modalTitle: string; component: React.ReactElement } } = {
    cart: {
      exitMessage: "",
      modalTitle: `Cart`,
      component: <CartStep nextStep={handleNext} {...{ store }} />,
    },
    custom_form: {
      exitMessage: "You have not completed this order, do you still want to exit?",
      modalTitle: "We need some extra details",
      component: (
        <CustomerFormStep prevStep={handlePrev} nextStep={handleNext} {...{ store, form, createOrderReq, setOrder }} />
      ),
    },
    customer_details: {
      exitMessage: "You have not completed this order, do you still want to exit?",
      modalTitle: "Complete Order",
      component: (
        <CustomerDetailsStep
          {...{ store, form, createOrderReq, scrollToTop, setOrder, formatAsCurrency }}
          prevStep={handlePrev}
          nextStep={handleNext}
          hasNextStep={customCheckoutForm?.length > 0}
        />
      ),
    },
    finalize_order: {
      exitMessage: "You have not sent this order, do you still want to leave this page?",
      modalTitle: "Finalize Order",
      component: (
        <FinalizeOrder
          {...{ store, order, checkoutOption, setCheckoutOption, changeStep }}
          prevStep={handlePrev}
          nextStep={handleNext}
          {...{ account, currencies, formatAsCurrency }}
        />
      ),
    },
    payment: {
      exitMessage: "You have not paid for this order, do you still want to leave this page?",
      modalTitle: "Make Payment",
      component: <MakeOrderPayment {...{ store, order, closeModal: handleExitCheckout, prevStep: handlePrev }} />,
    },
    success: {
      exitMessage: "",
      modalTitle: "Order Successful",
      component: (
        <OrderSuccess {...{ order, checkoutOption, closeModal: handleExitCheckout, storeName: store.name, account }} />
      ),
    },
  };

  return (
    <Modal {...{ show, toggle: () => handleExitCheckout(), title: steps[step].modalTitle }} size="midi">
      {steps[step].component}
    </Modal>
  );
};

export default CheckoutModal;

export interface CheckoutForm {
  coupon: string;
  name: string;
  email: string;
  phone: {
    code: string;
    digits: string;
  };
  deliveryMethod: DELIVERY_METHODS;
  address: string;
  deliveryContact: {
    phone: {
      code: string;
      digits: string;
    };
    name: string;
  };
  area: string;
  deliveringToSelf: boolean;
  currency: CURRENCIES;
  orderNotes?: string;
  customer_id?: string;
  extra_details?: Record<string, string>;
}

function setErrorText(arg0: string) {
  throw new Error("Function not implemented.");
}

const validationSchema = (deliveryAreas: DeliveryArea[], require_emails, customForm?: CustomCheckoutFormItem[]) =>
  Yup.object().shape({
    deliveringToSelf: Yup.boolean().required(),
    name: Yup.string().required("Name is required"),
    phone: phoneValidation,
    email: require_emails
      ? Yup.string().email("Please provide a valid email address").required("Please enter an email address")
      : Yup.string().notRequired(),
    area:
      deliveryAreas.length > 0
        ? Yup.string().when("deliveryMethod", {
            is: DELIVERY_METHODS.DELIVERY,
            then: Yup.string().required("Please Select an area"),
          })
        : undefined,
    address: Yup.string().when("deliveryMethod", {
      is: DELIVERY_METHODS.DELIVERY,
      then: Yup.string().required("Delivery address is required"),
    }),
    deliveryContact: Yup.object().when("deliveringToSelf", {
      is: false,
      then: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: phoneValidation,
      }),
    }),
    extra_details:
      customForm?.length > 0
        ? Yup.object().shape({
            ...customForm?.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.name]: curr.is_required
                  ? Yup.string().required(`${curr.name.split("_").join(" ")} is required`)
                  : null,
              }),
              {}
            ),
          })
        : null,
  });
